<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">
      <!-- Forgot Password v1 -->
      <b-card class="mb-0">
        <b-card-title class="mb-1">
          {{ $t("auth.sign_up") }}
        </b-card-title>
        <validation-observer ref="completeRules">
          <b-form method="POST" class="auth-reset-password-form mt-2" @submit.prevent="validationForm">
            <!-- password -->
            <b-form-group :label="$t('auth.password')" label-for="password">
              <validation-provider #default="{ errors }" name="password" vid="password" rules="required|password">
                <b-input-group class="input-group-merge" :class="errors.length > 0 ? 'is-invalid' : null">
                  <b-form-input
                    id="register-password"
                    v-model="formDatafinal.password"
                    :type="password1FieldType"
                    autocomplete="off"
                    :state="errors.length > 0 ? false : null"
                    class="form-control-merge"
                    name="register-password"
                    placeholder="············"
                  />
                  <b-input-group-append is-text>
                    <feather-icon class="cursor-pointer" :icon="password1ToggleIcon" @click="togglePassword1Visibility" />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- confirm password -->
            <b-form-group label-for="password-confirm" :label="$t('auth.confirm_password')">
              <validation-provider #default="{ errors }" name="confirmed" rules="required|confirmed:password">
                <b-input-group class="input-group-merge" :class="errors.length > 0 ? 'is-invalid' : null">
                  <b-form-input
                    id="password-confirm"
                    v-model="formDatafinal.confirmed"
                    :type="password2FieldType"
                    autocomplete="off"
                    class="form-control-merge"
                    :state="errors.length > 0 ? false : null"
                    name="confirmed"
                    placeholder="············"
                  />
                  <b-input-group-append is-text>
                    <feather-icon class="cursor-pointer" :icon="password2ToggleIcon" @click="togglePassword2Visibility" />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- submit button -->
            <b-button block type="submit" variant="primary" :disabled="submitProcessing">
              {{ $t("auth.sign_up") }}
            </b-button>
          </b-form>
        </validation-observer>
      </b-card>
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  BCard,
  BCardTitle,
  BCardText,
  BForm,
  BFormGroup,
  BInputGroup,
  BInputGroupAppend,
  BLink,
  BFormInput,
  BButton
} from "bootstrap-vue";
import useJwt from "@/auth/jwt/useJwt";
//import './style.css'
import { utilsMixin } from "@core/mixins/AuthForm/index.js";
export default {
  // props: {
  //   formData: {
  //     type: Object,
  //     required: true,
  //   },
  // },
  components: {
    BCard,
    BButton,
    BCardTitle,
    BCardText,
    BForm,
    BFormGroup,
    BInputGroup,
    BLink,
    BFormInput,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver
  },
  mixins: [utilsMixin],
  data() {
    return {
      // Toggle Password,
      formDatafinal: {},
      password1FieldType: "password",
      password2FieldType: "password"
    };
  },
  computed: {
    formData() {
      return this.$store.state.auth.dataAuth;
      // return this.$store.getters['auth/phoneObject'];
    },
    password1ToggleIcon() {
      return this.password1FieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    password2ToggleIcon() {
      return this.password2FieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    urlApi() {
      if (this.formData.firebase_verfy && this.formData.firebase_verfy == true) {
        return "complete/profile";
      }
      return "complete/temp/profile";
    }
  },
  mounted() {
    this.formDatafinal = this.formData;
  },
  methods: {
    togglePassword1Visibility() {
      this.password1FieldType = this.password1FieldType === "password" ? "text" : "password";
    },
    togglePassword2Visibility() {
      this.password2FieldType = this.password2FieldType === "password" ? "text" : "password";
    },
    saveData() {
      this.submitProcessing = true;
      // let formData=this.formData;
      let formData = {
        ...this.formDatafinal
      };

      this.$http
        .post(`${this.prefixApi}/${this.urlApi}`, formData)
        .then(response => {
          let data = response.data;
          let message = data.msg ? data.msg : data.message;
          this.submitProcessing = false;
          if (response.data.status === true) {
            this.showToast(message, "success");
            if (data.access_token) {
              this.disableButton = true;
              this.disableVerfiyButton = true;
              let accessToken = response.data.access_token;
              let userData = response.data.user;

              useJwt.setToken(response.data.access_token);
              useJwt.setRefreshToken(response.data.access_token);
              localStorage.setItem("userData", JSON.stringify(userData));
              localStorage.setItem("accessToken", accessToken);
              this.$router.push({ name: "dashboard.index" });
            } else {
              //  this.step_number = 2;
            }

            //  this.$router.push({ name: 'auth-login' })
          } else {
            this.showToast(message, "danger");
            // this.$router.push({ name: 'misc-error-404' })
          }
        })
        .catch(error => {
          this.submitProcessing = false;
          this.errorsHandler(error, "completeRules");
        });
    },
    validationForm() {
      this.$refs.completeRules.validate().then(success => {
        if (success) {
          this.saveData();
        }
      });
    }
  }
};
</script>

<style lang="scss">
@import "~@resources/scss/vue/pages/page-auth.scss";
</style>
